import React from 'react'
import { Section } from '../components/section'
import { graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layout'
import Img from "gatsby-image"
import { MDXRenderer } from 'gatsby-mdx';


export const query = graphql`

query ($file_name: String) {
    productImages: allFile(filter: {sourceInstanceName: {eq: "products"}, name: {eq: $file_name}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 2048) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          
          }
      }
    }
  }
`


export default ({ pageContext, ...props }) => {

    const {title, category} = pageContext.node.frontmatter

    let img = null
    props.data.productImages.edges.map(({node}) => {

        if (img || !node.childImageSharp)
            return null
        img = node.childImageSharp

        return null;
    })

    return (
        <Layout location={{ pathname: '/assortiment/' }}>

            <Section className="light">
                <Container>
                    <Row>
                        <Col md={6}>

                            <h3>{category}</h3>
                            <hr />

                            <h1>{title}</h1>

                            <MDXRenderer>{pageContext.node.code.body}</MDXRenderer>

                        </Col>

                        <Col md={6}>
                            {img && <Img fluid={img.fluid} />}
                        </Col>

                    </Row></Container></Section>

        </Layout>
    )
}
